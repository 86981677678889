import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../app/api/agent";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ButtonLoading from "../common/ButtonLoading";
import PageLoading from "../common/PageLoading";

function SubjectEdit(props) {
    const { id } = useParams(props);

    const navigate = useNavigate();

    const [subject, setSubject] = useState({
        id: "",
        categoryId:"",
        subjectName: "",
        description: ""
    });

    const [disable, setDisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [pageLoading, setPageLoading] = useState(true);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        categoryList();
        edit(id);
    }, [id]);

    function categoryList() {
        agent.Category.list().then(response => {
            setCategories(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
        });
    }


    function edit(id) {
        if (id > 0) {
            agent.Subject.edit(id)
                .then(response => {
                    if (response.id > 0) {
                        setSubject({
                            id: response.id,
                            categoryId: response.categoryId,
                            subjectName: response.subjectName,
                            description: response.description
                        });
                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setPageLoading(false);
                });
        }
    }

    const onSubmit = values => {
        setDisable(true);
        setLoading(true);

        const dataObject = {
            id: subject.id,
            subjectName: values.subjectName,
            description: values.description,
            categoryId: values.categoryId
        };

        if (subject.id > 0) {
            agent.Subject.update(dataObject)
                .then(response => {
                    navigate("/subject-list");
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setDisable(false);
                    setLoading(false);
                });
        }
    };

    const validationSchema = Yup.object({
        categoryId: Yup.string()
            .required("Category name is required"),
        subjectName: Yup.string()
            .required("Subject name is required")
            .max(128, "The length of 'Subject Name' must be '128' characters or fewer."),
        description: Yup.string()
            .required("Description is required")
            .max(128, "The length of 'Description' must be '128' characters or fewer."),
    });

    if (pageLoading)
        return (<PageLoading text="Loading..." />);

    return (<>
        <h3>Edi Subject</h3>
        <Formik
            enableReinitialize={true}
            initialValues={subject}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>

            <Form>
                <div className="card p-4">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <label htmlFor="categoryId" className="form-label">Category Name</label>
                            <Field as="select" name="categoryId" id="categoryId" className="form-control" >
                                <option value="">--Select--</option>
                                {categories.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.categoryName}</option>
                                })}
                            </Field>
                            <div className="text-danger" >
                                <ErrorMessage name="categoryId" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="subjectName" className="form-label">Subject Name</label>
                            <Field type="text" name="subjectName" id="subjectName" className="form-control" />
                            <div className="text-danger" >
                                <ErrorMessage name="subjectName" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <Field type="text" name="description" id="description" className="form-control" />
                        <div className="text-danger" >
                            <ErrorMessage name="description" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <button disabled={disable} type="submit" className="btn btn-outline-dark me-1">
                            Submit
                            {loading && <ButtonLoading />}
                        </button>
                        <Link to="/subject-list" className="btn btn-outline-secondary">Cancel</Link>
                    </div>
                </div>
            </Form>
        </Formik>
    </>);
}

export default SubjectEdit;