import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import agent from "../../app/api/agent";
import ButtonLoading from "../common/ButtonLoading";
import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';



function PostAdd() {

    const [disable, setDisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const post = {
        categoryId: "",
        subjectId: "",
        postTitle: "",
    };

    const [postDescription, setPostDescription] = useState("");

    const editor = useRef(null);
    const [content, setContent] = useState("");

    const [categories, setCategories] = useState([]);

    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        categoryList();
    }, []);

    function categoryList() {
        agent.Category.list().then(response => {
            setCategories(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
        });
    }

    function getByCategoryId(categoryId) {
        if (categoryId > 0) {
            agent.Subject.getSubjectByCategoryId(categoryId)
                .then(response => {
                    setSubjects(response);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                });
        }
    }

    const onSubmit = values => {
        setDisable(true);
        setLoading(true);

        const dataObject = {
            postTitle: values.postTitle,
            postDescription: postDescription,
            subjectId: values.subjectId 
        };

        agent.Post.add(dataObject)
            .then(response => {
            navigate("/post-list");
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setDisable(false);
            setLoading(false);
        });
    };

    const validationSchema = Yup.object({
        categoryId: Yup.string()
            .required("Category name is required"),
        subjectId: Yup.string()
            .required("Subject name is required"),
        postTitle: Yup.string()
            .required("Post title is required")
            .max(128, "The length of 'Post title' must be '128' characters or fewer."),
    });

   

    return (<>
        <h3>Add Post</h3>
        <Formik
            initialValues={post}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {props => (
                <Form>
                    <div className="card p-4">
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label htmlFor="categoryId" className="form-label">Category Name</label>
                                <Field onChange={(e) => {
                                    getByCategoryId(e.target.value);
                                    props.handleChange(e);
                                }}
                                    value={props.values.categoryId}
                                    as="select" name="categoryId" id="categoryId" className="form-control" >
                                    <option value="">--Select--</option>
                                    {categories.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.categoryName}</option>
                                    })}
                                </Field>
                                <div className="text-danger" >
                                    <ErrorMessage name="categoryId" />
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <label htmlFor="subjectId" className="form-label">Subject Name</label>
                                <Field as="select" name="subjectId" id="subjectId" className="form-control" >
                                    <option value="">--Select--</option>
                                    {subjects.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.subjectName}</option>
                                    })}
                                </Field>
                                <div className="text-danger" >
                                    <ErrorMessage name="subjectId" />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-sm-12">
                                <label htmlFor="postTitle" className="form-label">Post Title</label>
                                <Field type="text" name="postTitle" id="postTitle" className="form-control" />
                                <div className="text-danger" >
                                    <ErrorMessage name="postTitle" />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-sm-12">
                                <label htmlFor="postDescription" className="form-label">Description</label>
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    onBlur={newContent => setContent(newContent)}
                                    onChange={newContent => {
                                        setPostDescription(newContent);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <button disabled={disable} type="submit" className="btn btn-outline-dark me-1">
                                Submit{loading && <ButtonLoading />}
                            </button>
                            <Link to="/post-list" className="btn btn-outline-secondary">Cancel</Link>
                        </div>
                    </div>
                </Form>
            )}
            
        </Formik>
    </>);
}

export default PostAdd;