import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { Link } from "react-router-dom";
import DateToString from "../../app/util/DateToString";
import PageLoading from "../common/PageLoading";

import { confirmAlert } from 'react-confirm-alert'; // Import
import Pagination from "../common/Pagination";



function PostList() {

    const [posts, setPosts] = useState([]);

    const [loading, setLoading] = useState(true);

    const [post, setPost] = useState({
        postTitle: ""
    });

    const [pageParams] = useState({
        pageNumber: 1,
        pageSize: 6
    });

    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0
    });


    useEffect(() => {
        agent.Post.list(pageParams.pageNumber, pageParams.pageSize)
            .then(response => {
                setPosts(response.data);
                setPagination(response.pagination);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [pageParams.pageNumber, pageParams.pageSize]);


    function deleteRow(id) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this row?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        agent.Post.delete(id)
                            .then(response => {
                                list();
                            }).catch(error => {
                                console.log(error)
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    //This method gets call when some one search for a specific recard
    const handleChange = (e) => {
        setPost({ postTitle: e.currentTarget.value })
        //Search
        if (e.currentTarget.value.length > 0) {
            agent.Post.search(pageParams.pageNumber, pageParams.pageSize,e.currentTarget.value)
                .then(response => {
                    setPosts(response.data);
                    setPagination(response.pagination);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                });
        }
        //list
        else {
            list();
        }
    };


    function list() {
        agent.Post.list(pageParams.pageNumber, pageParams.pageSize)
            .then(response => {
                setPosts(response.data);
                setPagination(response.pagination);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    }

  //This method gets call when someone clickon pagination button
    function handlePageChange(number) {
        //Search for specific recards
        if (post.postTitle.length > 0) {
            agent.Post.search(number, pageParams.pageSize, post.postTitle)
                .then(response => {
                    setPosts(response.data);
                    setPagination(response.pagination);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                });
        }
        //Load app recards
        else {
            agent.Post.list(number, pageParams.pageSize)
                .then(response => {
                    setPosts(response.data);
                    setPagination(response.pagination);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    if (loading)
        return (<PageLoading text="Loading..." />);

    return (<>
        <div className="row">
            <div className="col-sm-6">
                <Link to="/post-add" className="btn btn-outline-dark">Add New</Link>
            </div>
            <div className="col-sm-6">
                <input value={post.postTitle} onChange={handleChange} type="text" name="postTitle" id="postTitle" className="form-control" placeholder="Search..." />
            </div>
        </div>

        <h3>Post List</h3>
        <div className="card">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Post Title</th>
                            <th>Created On</th>
                            <th>Created By</th>
                            <th>Modified On</th>
                            <th>Modified By</th>
                            <th style={{minWidth:"200px"}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td title={item.postTitle}>
                                    {item.postTitle.length < 35 ? item.postTitle : item.postTitle.substring(0, 35) + "..."}
                                </td>
                                <td>{DateToString(item.createdOn)}</td>
                                <td>{item.createdBy}</td>
                                <td>{DateToString(item.modifiedOn)}</td>
                                <td>{item.modifiedBy}</td>
                                <td>
                                    <Link to={`/post-edit/${item.id}`} className="btn btn-outline-dark me-2">Edit</Link>
                                    <button onClick={() => deleteRow(item.id)} className="btn btn-outline-danger me-2">Delete</button>
                                    <Link to={`/post-detail/${item.id}`} className="btn btn-outline-dark">View</Link>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <Pagination metaData={pagination} button={5} handlePageChange={handlePageChange} />
            </div>
        </div></>);
}

export default PostList;