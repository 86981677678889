import { useEffect, useState } from "react";

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

function Pagination({ metaData,button, handlePageChange  }) {
    const { currentPage, totalPages, pageSize, totalCount } = metaData;

    const [displayPages, setDisplayPages] = useState([]);

    const [previous, setPrevious] = useState(false);

    const [next, setNext] = useState(true);
   
    useEffect(() => {
        setDisplayPages(range(1, totalPages > button ? button : totalPages));

        if (totalPages <= button)
            setNext(false);
    }, [setDisplayPages, totalPages, button]);

    function handlePrevious() {
        setNext(true);
        const start = displayPages[0] - button;
        const end = displayPages[0] - 1;

        if (start >= 0) {
            setDisplayPages(range(start, end));
            handlePageChange(end);
        }
        
        if (start <= 1)
            setPrevious(false);
        
    }

    function handleNext() {
        setPrevious(true);
        const start = displayPages[displayPages.length - 1] + 1;
        const end = displayPages[displayPages.length - 1] + button;        
        
        if (end <= totalPages) {
            setDisplayPages(range(start, end));
            handlePageChange(start);
        }
        else {
            setDisplayPages(range(start, totalPages));
            handlePageChange(start);
            setNext(false);
        }
        
    }

    return (<div className="container">
        <div className="row">
            <div className="col-sm-8">
                <ul className="pagination">
                    <li className="page-item">
                        <button className="page-link" title="Previous" style={!previous ? { cursor: "no-drop" } : { cursor: "pointer" }} disabled={!previous} onClick={handlePrevious}>
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li>
                    {
                        displayPages.map(number =>
                            <li className="page-item" key={number}>
                                <button className={currentPage === number ? "page-link active" : "page-link"} onClick={() => handlePageChange(number)} >{number}</button>
                            </li>
                        )
                    }
                    <li className="page-item">
                        <button className="page-link" title="Next" style={!next ? { cursor: "no-drop" } : { cursor: "pointer" }} disabled={!next} onClick={handleNext}>
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li>
                </ul >
            </div>

            <div className="col-sm-4">
                <p className="text-end mt-1">
                    Displaying {(currentPage - 1) * pageSize + 1}
                    &nbsp;-&nbsp;{currentPage * pageSize > totalCount ? totalCount : currentPage * pageSize}
                    &nbsp;of {totalCount}&nbsp;items
                </p>
            </div>
        </div>
    </div>);
}

export default Pagination;