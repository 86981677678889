import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { Link } from "react-router-dom";
import DateToString from "../../app/util/DateToString";
import PageLoading from "../common/PageLoading";

import { confirmAlert } from 'react-confirm-alert'; // Import


function SubjectList() {

    const [subjects, setSubjects] = useState([]);

    const [loading, setLoading] = useState(true);

    const [subject, setSubject] = useState({
        subjectName: ""
    });

    useEffect(() => {
        list();
    }, []);

    function list() {
        agent.Subject.list()
            .then(response => {
                setSubjects(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    function deleteRow(id) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure you want to delete this row?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        agent.Subject.delete(id)
                            .then(response => {
                                list();
                            }).catch(error => {
                                console.log(error)
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleChange = (e) => {
        setSubject({ subjectName: e.currentTarget.value })
        if (e.currentTarget.value.length > 0) {
            agent.Subject.search(e.currentTarget.value)
                .then(response => {
                    setSubjects(response);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                });
        }
        else {
            list();
        }
    };

    if (loading)
        return (<PageLoading text="Loading..." />);

    return (<>
        <div className="row">
            <div className="col-sm-6">
                <Link to="/subject-add" className="btn btn-outline-dark">Add New</Link>
            </div>
            <div className="col-sm-6">
                <input value={subject.subjectName} onChange={handleChange} type="text" name="categoryName" id="categoryName" className="form-control" placeholder="Search..." />
            </div>
        </div>


        <h3>Subject List</h3>
        <div className="card">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Category Name</th>
                            <th>Subject Name</th>
                            <th>Description</th>
                            <th>Created On</th>
                            <th>Created By</th>
                            <th>Modified On</th>
                            <th>Modified By</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.categoryName}</td>
                                <td>{item.subjectName}</td>
                                <td>{item.description}</td>
                                <td>{DateToString(item.createdOn)}</td>
                                <td>{item.createdBy}</td>
                                <td>{DateToString(item.modifiedOn)}</td>
                                <td>{item.modifiedBy}</td>
                                <td>
                                    <Link to={`/subject-edit/${item.id}`} className="btn btn-outline-dark me-2">Edit</Link>
                                    <button onClick={() => deleteRow(item.id)} className="btn btn-outline-danger">Delete</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div></>);
}

export default SubjectList;