import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import * as Yup from "yup";
import agent from "../../app/api/agent";
import { useStoreContext } from "../../app/context/StoreContext";
import ButtonLoading from "../common/ButtonLoading";



function Login() {
    const [disable, setDisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { setEmail, setIsAuthenticated } = useStoreContext();

    const user = {
        userName: "",
        password: ""
    };

    const onSubmit = values => {
        setDisable(true);
        setLoading(true);
        var dataObj = {
            "userName": values.userName,
            "password": values.password
        };

        console.log(dataObj)
        agent.Account.login(dataObj)
            .then(response => {
                localStorage.setItem("token", response.token);
                localStorage.setItem("email", response.email);
                setEmail(response.email);
                setIsAuthenticated(true);

                navigate("/home-page");
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setDisable(false);
            setLoading(false);
        });
    };

    const validationSchema = Yup.object({
        userName: Yup.string()
            .required("User name is required")
            .max(128, "The length of 'User name' must be '128' characters or fewer."),
        password: Yup.string()
            .required("Password is required")
            .max(128, "The length of 'Password' must be '128' characters or fewer."),
    });

    return (<>
        <Formik
            initialValues={user}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                <div className="container mt-5">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                            
                            <div className="card px-5 py-5">
                                <p className="h3 text-center pb-5">Love 2 Programming</p>
                                <div className="mb-4"> <label htmlFor="userName">Username</label>
                                    <Field type="text" name="userName" id="userName" className="form-control" />
                                    <div className="text-danger" >
                                        <ErrorMessage name="userName" />
                                    </div>
                                </div>
                                <div className="mb-4"> <label htmlFor="password">Password</label>
                                    <Field type="password" name="password" id="password" className="form-control" />
                                    <div className="text-danger" >
                                        <ErrorMessage name="password" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button type="submit" disabled={disable} className="btn btn-dark w-100">Login {loading && <ButtonLoading />}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    </>);
}

export default Login;