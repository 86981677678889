import { Link } from "react-router-dom";
import { useStoreContext } from "../app/context/StoreContext";

function Header() {
    const { email,setEmail, isAuthenticated, setIsAuthenticated } = useStoreContext();

    if (isAuthenticated) {
        return (
            <>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark mb-4">
                    <div className="container">
                        <Link to="/home-page" className="navbar-brand">Love2Programming</Link>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarCollapse" >
                            <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                <li className="nav-item">
                                    <Link to="/post-list" className="nav-link">Post List</Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav  mb-2 mb-md-0">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false">Administrations</a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdown03">
                                        <Link to="/category-list" className="dropdown-item">Category</Link>
                                        <Link to="/subject-list" className="dropdown-item">Subject</Link>
                                        <Link to="/user-list" className="dropdown-item">Users</Link>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <span className="nav-link">{email}</span>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={() => {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("email");
                                        setEmail(null);
                                        setIsAuthenticated(false);
                                    }}>Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
    else {
        return (
            <>
               
            </>
        );
    }
}

export default Header;