import axios from "axios";
import { toast } from "react-toastify";
//axios.defaults.baseURL = "https://localhost:7164/api/";

axios.defaults.baseURL = "https://love2programming.com/api/";

const responseBody = (response) => response.data;

axios.interceptors.request.use(config => {
    var token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`
    return config;
});

//const sleep = () => new Promise(resolve => setTimeout(resolve, 100));
axios.interceptors.response.use(async response => {
   // await sleep();

    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = { data: response.data, pagination: JSON.parse(pagination) };
        return response;
    }

    
    return response;
}, (error) => {
    const { data, status } = error.response;
   
    switch (status) {
        case 400:
            toast.error(data.title);
            break;
        case 401:
            localStorage.removeItem("token");
            window.location = "/";
            break;
        case 403:
            toast.error("You do not have permission to view this resource.");
            break;
        case 500:
            toast.error(data.error);
            break;
        default:
            break;
    }
    return Promise.reject(error.response);
});


const requests = {
    get: (url) => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    put: (url, body) => axios.put(url, body).then(responseBody),
    delete: (url) => axios.delete(url).then(responseBody)
};

const Category = {
    list: () => requests.get(`categories/gets`),
    add: (body) => requests.post(`categories/add`, body),
    edit: (id) => requests.get(`categories/get/${id}`),
    update: (body) => requests.put(`categories/update`, body),
    delete: (id) => requests.delete(`categories/delete/${id}`),
    search: (categoryName) => requests.get(`categories/search/${categoryName}`),
};

const Subject = {
    list: () => requests.get(`subjects/gets`),
    add: (body) => requests.post(`subjects/add`, body),
    edit: (id) => requests.get(`subjects/get/${id}`),
    update: (body) => requests.put(`subjects/update`, body),
    delete: (id) => requests.delete(`subjects/delete/${id}`),
    search: (subjectName) => requests.get(`subjects/search/${subjectName}`),
    getSubjectByCategoryId: (categoryId) => requests.get(`subjects/getsByCategoryId/${categoryId}`),
};



const Post = {
    list: (pageNumber, pageSize) => requests.get(`posts/gets?pageNumber=${pageNumber}&pageSize=${pageSize}`),
    add: (body) => requests.post(`posts/add`, body),
    edit: (id) => requests.get(`posts/get/${id}`),
    update: (body) => requests.put(`posts/update`, body),
    delete: (id) => requests.delete(`posts/delete/${id}`),
    getDetails: (id) => requests.get(`posts/getDetails/${id}`),
    //search: (name) => requests.get(`posts/search/${name}`),
    search: (pageNumber, pageSize, search) => requests.get(`posts/search?pageNumber=${pageNumber}&pageSize=${pageSize}&Search=${search}`),
};

const Account = {
    login: (body) => requests.post(`accounts/login`, body),
    register: (body) => requests.post(`accounts/register`, body),
    currentUser: () => requests.get(`accounts/currentUser`),
    list: () => requests.get(`accounts/gets`),
};


const agent = {
    Category,
    Subject,
    Post,
    Account
};

export default agent;