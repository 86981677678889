import { useEffect } from "react";
import agent from "../../app/api/agent";

function HomePage() {
    
    useEffect(() => {
        agent.Account.currentUser()
            .then(response => {
                localStorage.setItem("token", response.token);
            }).catch(error => {
                console.log(error);
            });
    }, []);
    
    return (<>
        Home Page
    </>);
}

export default HomePage;