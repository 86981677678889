import { createContext, useContext, useState } from "react";


export const StoreContext = createContext(undefined);

export function useStoreContext() {
    const context = useContext(StoreContext);

    if (context === undefined)
        throw Error("Oopps we do not seem to be inside the provider")
    
    return context;
}

export function StoreProvider({children}) {
    const [email, setEmail] = useState(useState(localStorage.getItem("email") !== null ? localStorage.getItem("email") : null));

    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("token") !== null ? true : false);

    return (<StoreContext.Provider value={{ email, setEmail, isAuthenticated, setIsAuthenticated }}>
        {children}
    </StoreContext.Provider>);
}