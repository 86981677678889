import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import agent from "../../app/api/agent";
import DateToString from "../../app/util/DateToString";
import PageLoading from "../common/PageLoading";

function PostDetail(props) {

    const { id } = useParams(props);

    const [post, setPost] = useState({});

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        detail(id);
    }, [id]);

    function detail(id) {
  
        if (id > 0) {
            agent.Post.getDetails(id)
                .then(response => {
                    console.log(response);
                    if (response.id > 0) {
                        setPost({
                            postId: response.id,
                            postTitle: response.postTitle,
                            postDescription: response.postDescription,
                            createdOn: response.createdOn,
                            createdBy: response.createdBy,
                            modifiedOn: response.modifiedOn,
                            modifiedBy: response.modifiedBy,
                            publishStatus: response.publishStatus,
                            publishedOn: response.publishedOn,
                            publishedBy: response.publishedBy,
                            subjectName: response.subjectName,
                           
                        });
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
        else {
            setLoading(false);
        }
        
    }

    if (loading)
        return (<PageLoading text="Loading..." />);
    
    return (<>
        <h3>Post Detail</h3>
        <div className="card p-4  mb-5">
            <table className="table">
                <thead className="align-middle">
                    <tr>
                        <th>Created On:</th>
                        <td>{DateToString(post.createdOn)}</td>
                    </tr>
                    <tr>
                        <th>Created By:</th>
                        <td>{post.createdBy}</td>
                    </tr>
                    <tr>
                        <th>Modified On:</th>
                        <td>{DateToString(post.modifiedOn)}</td>
                    </tr>
                    <tr>
                        <th>Modified By:</th>
                        <td>{post.modifiedBy}</td>
                    </tr>
                    <tr>
                        <th>Published On:</th>
                        <td>{DateToString(post.publishedOn)}</td>
                    </tr>
                    <tr>
                        <th>Published By:</th>
                        <td>{post.publishedBy}</td>
                    </tr>
                    <tr>
                        <th>Publish Status:</th>
                        <td>{post.publishStatus === true ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                        <th>Subject Name:</th>
                        <td>{post.subjectName}</td>
                    </tr>
                    <tr>
                        <th>Post Title:</th>
                        <td>{post.postTitle}</td>
                    </tr>
                    <tr>
                        <th>Post Description:</th>
                        <td>
                            <div dangerouslySetInnerHTML={{ __html: post.postDescription }} />
                        </td>
                    </tr>
                </thead>
            </table>
            <div>
                <Link to={`/post-edit/${post.postId}`} className="btn btn-outline-dark me-2">Edit</Link>
                <Link to="/post-list" className="btn btn-outline-secondary">Cancel</Link>
            </div>
        </div>
    </>);
}

export default PostDetail;