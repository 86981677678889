
function PageLoading({text}) {
        return (<>
            <div  className="d-flex align-items-center justify-content-center" style={{marginTop:"25%"}}>
                <img src={process.env.PUBLIC_URL + '/loading_gray.gif'} alt="description" />
                <strong style={{ color: "gray" }}>{text}</strong>
            </div>
        </>);
}

export default PageLoading;