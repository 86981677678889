import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../app/api/agent";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ButtonLoading from "../common/ButtonLoading";
import PageLoading from "../common/PageLoading";

function CategoryEdit(props) {
    const { id } = useParams(props);

    const navigate = useNavigate();

    const [category, setCategory] = useState({
        id: 0,
        categoryName: "",
        description: ""
    });

    const [disable, setDisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        if (id > 0) {
            agent.Category.edit(id)
                .then(response => {
                    if (response.id > 0)
                        setCategory(response);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setPageLoading(false);
                });
        }
       
    }, [id]);

    const onSubmit = values => {
        setDisable(true);
        setLoading(true);
        if (category.id > 0) {
            agent.Category.update(values).then(response => {
                navigate("/category-list");
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setDisable(false);
                setLoading(false);
            });
        }
    };

    const validationSchema = Yup.object({
        categoryName: Yup.string()
            .required("Category name is required")
            .max(128, "The length of 'Category Name' must be '128' characters or fewer."),
        description: Yup.string()
            .required("Description is required")
            .max(128, "The length of 'Description' must be '128' characters or fewer."),
    });

    if (pageLoading)
        return (<PageLoading text="Loading..." />);
   
    return (<>
        <h3>Edi Category</h3>
        <Formik
            enableReinitialize={true}
            initialValues={category}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>

            <Form>
                <div className="card p-4">
                    <div className="mb-3">
                        <label htmlFor="categoryName" className="form-label">Category Name</label>
                        <Field type="text" name="categoryName" id="categoryName" className="form-control" />
                        <div className="text-danger" >
                            <ErrorMessage name="categoryName" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <Field type="text" name="description" id="description" className="form-control" />
                        <div className="text-danger" >
                            <ErrorMessage name="description" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <button disabled={disable} type="submit" className="btn btn-outline-dark me-1">
                            Submit
                            {loading && <ButtonLoading />}
                        </button>
                        <Link to="/category-list" className="btn btn-outline-secondary">Cancel</Link>
                    </div>
                </div>
            </Form>
        </Formik>
    </>);
}

export default CategoryEdit;