import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { Link } from "react-router-dom";
import DateToString from "../../app/util/DateToString";
import PageLoading from "../common/PageLoading";

import { confirmAlert } from 'react-confirm-alert'; // Import


function UserList() {

    const [users, setUsers] = useState([]);

    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState({
        userName: ""
    });

    useEffect(() => {
        list();
    }, []);

    function list() {
        agent.Account.list().then(response => {
            setUsers(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }


    const handleChange = (e) => {
        // setUser({ userName: e.currentTarget.value })
        // if (e.currentTarget.value.length > 0) {
        //     agent.Category.search(e.currentTarget.value)
        //         .then(response => {
        //             setCategories(response);
        //         }).catch(error => {
        //             console.log(error);
        //         }).finally(() => {
        //         });
        // }
        // else {
        //     list();
        // }
    };

    if (loading)
        return (<PageLoading text="Loading..." />);

    return (<>
        <div className="row">
            <div className="col-sm-6">
                <Link to="/category-add" className="btn btn-outline-dark">Add New</Link>
            </div>
            <div className="col-sm-6">
                <input value={user.userName} onChange={handleChange} type="text" name="userName" id="userName" className="form-control" placeholder="Search..." />
            </div>
        </div>


        <h3>User List</h3>
        <div className="card">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Roles</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.userName}</td>
                                <td>{item.email}</td>
                                <td>{item.roles.map((role, roleIndex) => {
                                    if (item.roles.length > 1) {
                                        if (role === item.roles[item.roles.length - 1]) {
                                            return <span key={roleIndex}>{role}</span>
                                        }
                                        else {
                                            return <span key={roleIndex}>{role}, &nbsp;</span>
                                        }
                                    }
                                    else {
                                        return <span key={roleIndex}>{role}</span>
                                    }
                                })}</td>
                                <td>
                                    <Link to={`/category-edit/${item.id}`} className="btn btn-outline-dark me-2">Edit</Link>
                                   
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div></>);
}

export default UserList;