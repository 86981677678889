import { Routes, Route } from "react-router";
import Header from './layout/Header';

import HomePage from "./components/home/HomePage";
import CategoryList from "./components/category/CategoryList";
import CategoryAdd from "./components/category/CategoryAdd";
import CategoryEdit from "./components/category/CategoryEdit";

import SubjectList from "./components/subject/SubjectList";
import SubjectAdd from "./components/subject/SubjectAdd";
import SubjectEdit from "./components/subject/SubjectEdit";

import { ToastContainer } from "react-toastify";
import PostList from "./components/post/PostList";
import PostAdd from "./components/post/PostAdd";
import PostEdit from "./components/post/PostEdit";
import PostDetail from "./components/post/PostDetail";
import Login from "./components/authentication/Login";
import { useStoreContext } from "./app/context/StoreContext";
import UserList from "./components/authentication/UserList";


function App() {

  const {  isAuthenticated } = useStoreContext();

  if (isAuthenticated) {
    return (
      <>
        <Header />
        <ToastContainer position="bottom-right" hideProgressBar />
        <div className="container">
          <Routes>
            <Route path="/home-page" element={<HomePage />} />
            <Route path="/category-list" element={<CategoryList />} />
            <Route path="/category-add" element={<CategoryAdd />} />
            <Route path="/category-edit/:id" element={<CategoryEdit />} />
            <Route path="/subject-list" element={<SubjectList />} />
            <Route path="/subject-add" element={<SubjectAdd />} />
            <Route path="/subject-edit/:id" element={<SubjectEdit />} />
            <Route path="/post-list" element={<PostList />} />
            <Route path="/post-add" element={<PostAdd />} />
            <Route path="/post-edit/:id" element={<PostEdit />} />
            <Route path="/post-detail/:id" element={<PostDetail />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/home-page" element={<HomePage />} />
          </Routes>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <ToastContainer position="bottom-right" hideProgressBar />
        <div className="container">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </div>
      </>);
  }
}

export default App;
