import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import agent from "../../app/api/agent";
import ButtonLoading from "../common/ButtonLoading";

function CategoryAdd() {
    const [disable, setDisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const category = {
        categoryName: "",
        description: ""
    };

    const onSubmit = values => {
        setDisable(true);
        setLoading(true);
        agent.Category.add(values).then(response => {
            navigate("/category-list");
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setDisable(false);
            setLoading(false);
        });
    };

    const validationSchema = Yup.object({
        categoryName: Yup.string()
            .required("Category name is required")
            .max(128, "The length of 'Category Name' must be '128' characters or fewer."),
        description: Yup.string()
            .required("Description is required")
            .max(128, "The length of 'Description' must be '128' characters or fewer."),
    });

    return (<>
        <h3>Add Category</h3>
        <Formik
            initialValues={category}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>

            <Form>
                <div className="card p-4">
                    <div className="mb-3">
                        <label htmlFor="categoryName" className="form-label">Category Name</label>
                        <Field type="text" name="categoryName" id="categoryName" className="form-control" />
                        <div className="text-danger" >
                            <ErrorMessage name="categoryName" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <Field type="text" name="description" id="description" className="form-control" />
                        <div className="text-danger" >
                            <ErrorMessage name="description" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <button disabled={disable} type="submit" className="btn btn-outline-dark me-1">
                            Submit{loading && <ButtonLoading />}
                        </button>
                        <Link to="/category-list" className="btn btn-outline-secondary">Cancel</Link>
                    </div>
                </div>
            </Form>
        </Formik>
    </>);
}

export default CategoryAdd;